import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from 'environment';

export const WEEKLY_CERT_LEVELS = {
  1:5,
  2:10,
  3:25,
  4:50,
  5:60
};
export const DOMAIN_CERT_LEVELS = {
  1: 3,
  2: 5,
  3: 8,
  4: 10,
  5: 12
};

export interface certificateData {
  type: string,
  level: number,
  domain: number | null,
  achieved: string | null,
  language: string | null,
}

export interface certificateNoticeData {
  type: string,
  level: number,
  domain_uid: number | null,
  achieved: string | null,
}


@Injectable({
  providedIn: 'root'
})
export class CertificatesService {

  constructor(private _http: HttpClient) {}

  addCertificate(): Observable<any> {
    return this._http.post<any>(`${config.apiBase}member/certificate/add`, {});
  }

  loadCertificateNotifications(): Observable<certificateNoticeData[]> {
    return this._http.get<certificateNoticeData[]>(`${config.apiBase}member/certificate/notification/list`);
  }

  updateNotification( reqBody: {certificates:Array<certificateNoticeData>} ) {
    return this._http.put(`${config.apiBase}member/certificate/notification/update`, reqBody);
  }

  loadCertificates(): Observable<certificateData[]> {
    return this._http.get<certificateData[]>(`${config.apiBase}member/certificate/list`);
  }


  downloadCertificate(reqBody: any) {
   this._http.post<any>(`${config.apiBase}member/certificate/create`, reqBody,) 
      .subscribe({
        next: (response: { type: string; data: number[] }) => {
          // Create a blob from the response
          const uint8Array = new Uint8Array(response.data);
          const blob = new Blob([uint8Array], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'certificate.pdf';
          link.click();
          window.URL.revokeObjectURL(url);
        },
        error: (error) => {
          console.error('Error downloading PDF:', error);
        },
      })
  }

}
