<div class="modal-header external">
  <h5 class="modal-title" id="exampleModalLongTitle">{{page.modalTitle}}</h5>
  <button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-label=page.close>
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body external">
  <form [formGroup]="form" (ngSubmit)="onSave()">
    <div class="row">
      <div class="form-group col-sm-12 col-md-4">
        <label for="assessmentName">{{page.assessmentName}}</label>
        <input id="assessmentName" formControlName="assessmentName" maxlength="40" type="text"
          placeholder="{{page.firstName}}" class="form-control"
          [ngClass]="{'danger-class': f.assessmentName.errors && f.assessmentName.touched}" />
        <div *ngIf="f.assessmentName.errors && f.assessmentName.touched" class="invalid-feedback">
          <div *ngIf="f.assessmentName.errors.required || f.assessmentName.errors?.['whitespace']"> {{page.assessmentNameRequired}}</div>
          <div *ngIf="!f.assessmentName.errors.required && ( f.assessmentName.errors.maxlength?.actualLength > 40)">
            {{page.assessmentName + page.nameLength}}</div>
        </div>
      </div>
      <div class="form-group col-sm-12 col-md-4">
        <label for="firstName">{{page.firstName}}</label>
        <input id="firstName" (keypress)="keyPressOnlyAlphabets($event)" formControlName="firstName" maxlength="40"
          type="text" placeholder="{{page.firstName}}" class="form-control"
          [ngClass]="{'danger-class': f.firstName.errors && f.firstName.touched}" />
        <div *ngIf="f.firstName.errors && f.firstName.touched" class="invalid-feedback">
          <div *ngIf="f.firstName.errors.required || f.firstName.errors?.['whitespace']"> {{page.firstNameRequired}}</div>
          <div *ngIf="!f.firstName.errors.required && ( f.firstName.errors.maxlength?.actualLength > 40)">
           {{page.firstName + page.nameLength}}</div>
        </div>
      </div>
      <div class="form-group col-sm-12 col-md-4">
        <label for="lastName">{{page.lastName}}</label>
        <input id="lastName" (keypress)="keyPressOnlyAlphabets($event)" formControlName="lastName" maxlength="40"
          type="text" placeholder="{{page.lastName}}" [ngClass]="{'danger-class': f.lastName.errors && f.lastName.touched}"
          class="form-control" />
        <div *ngIf="f.lastName.errors && f.lastName.touched" class="invalid-feedback">
          <div *ngIf="f.lastName.errors.required || f.lastName.errors?.['whitespace']"> {{page.lastNameRequired}}</div>
          <div *ngIf="!f.lastName.errors.required && ( f.lastName.errors.maxlength?.actualLength > 40)">
            {{page.lastName + page.nameLength}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-md-4">
        <label for="email">{{page.email}}</label>
        <input id="email" type="text" maxlength="320" formControlName="email" name="email" ngModel email
          [ngClass]="{'danger-class': f.email.errors && f.email.touched}" placeholder="{{page.email}}" class="form-control" />
        <div *ngIf="f.email.errors && f.email.touched" class="invalid-feedback">
          <div *ngIf="f.email.errors.required || f.email.errors?.['whitespace']"> {{page.emailRequired}}</div>
          <div *ngIf="f.email.errors.pattern">{{page.emailInvalid}}</div>
        </div>
      </div>
      <div class="form-group col-sm-12 col-md-4">
        <label for="relationship">{{page.relationship}}</label>
        <input id="relationship" type="text" maxlength="40" formControlName="relationship" name="relationship"
          placeholder="{{page.relationship}}" class="form-control"
          [ngClass]="{'danger-class': f.relationship.errors && f.relationship.touched}" />
        <div *ngIf="f.relationship.errors && f.relationship.touched" class="invalid-feedback">
          <div *ngIf="f.relationship.errors.required || f.relationship.errors?.['whitespace']">{{page.relationshipRequired}}</div>
          <div *ngIf="!f.relationship.errors.required && ( f.relationship.errors.maxlength?.actualLength > 40)">
           {{ page.relationship + page.nameLength}}</div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center modal-footer bottomBtnsModal footer-inner-action">
      <button class="btn btn-primary ml-3">{{labels.save}}</button>
      <button type="button" class="cancel mr-0" data-dismiss="modal" aria-label=page.close
        (click)="closeModal()">{{labels.cancel}}</button>
    </div>
  </form>
</div>
