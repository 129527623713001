<app-member-main-header
  [showAchievements]="showAchievements"
  (toggleMenu)="toggleMenu()"
  (toggleAchievements)="toggleSlidingPanel()"
></app-member-main-header>

<app-member-main-menu  (toggleMenu)="toggleMenu()" class="main-sidebar sidebar-dark-primary elevation-4">
</app-member-main-menu>

<div *ngIf="loaded" class="content-wrapper">
  <router-outlet></router-outlet>
</div>

<app-member-main-footer class="main-footer"></app-member-main-footer>
<aside class="control-sidebar control-sidebar-dark"></aside>
<div id="sidebar-overlay" (click)="toggleMenu()"></div>

<app-certificate-list *ngIf="loaded" #slidingPanel [isOpen]="isSlidingPanelOpen">
</app-certificate-list>
