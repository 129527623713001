import { Component, Input, OnInit} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CertificatesService, certificateData, DOMAIN_CERT_LEVELS, WEEKLY_CERT_LEVELS } from '@services/member';
import { LanguageService } from '@services/public';

const PAGE_PATH: string = 'pages.member.certificateModal.page';

@Component({
  selector: 'app-certificate-list',
  templateUrl: './certificate-list.component.html',
  styleUrls: ['./certificate-list.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateX(-100%)'
      })),
      state('out', style({
        transform: 'translateX(0)'
      })),
      transition('in => out', animate('200ms ease-in-out')),
      transition('out => in', animate('200ms ease-in-out'))
    ])
  ]
})
export class CertificateListComponent implements OnInit {
  @Input() isOpen = false;
  certificates: Array<certificateData>;
  page: {[key: string]: string } = {
    achieved: '',
    congrats: '',
    download: '',
    level: '',
    messageMany: '',
    messageOne: '',
    title: '',
    weekly: ''
  };
  domains: {[key: string]: string } = {
    1: '',
    2: '',
    3: '',
    4: '',
    5: ''
  };
  language: string = this._languageSvc.language;

  constructor(
    private _certificatesSvc: CertificatesService,
    private _languageSvc: LanguageService
  ) { }

  ngOnInit(): void {
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
      });
    this._languageSvc.get([`domains2`]).then(
      value => {
        if (
          typeof value[`domains2`] !== 'object' ||
          value[`domains2`] === null
        ) return;
        for (const key in value[`domains2`]) {
          if (this.domains.hasOwnProperty(key)) {
            this.domains[key] = value[`domains2`][key].name;
          }
        }}
    );
    this._certificatesSvc.loadCertificates().subscribe(res => {
      this.certificates = res;
    });
  }

  downloadCertificate(type: string, level: number,
    domain: number | null, achieved: string, language: string) {
      this._certificatesSvc.downloadCertificate(
      {
        type: type,
        level: level,
        domain_uid: domain,
        achieved: achieved,
        language: language,
        numOfChallenges: type === 'weekly'? WEEKLY_CERT_LEVELS[level] : DOMAIN_CERT_LEVELS[level]
      });
  }

}
