import {Injectable, RendererFactory2 } from '@angular/core';
import { Guides } from '@components';
import { FunctionQueue, toVoidPromise} from '@util';
import { Observable, Subject, Subscription, map } from 'rxjs';
import { config } from "environment";
import { HttpClient} from "@angular/common/http";
import { LanguageService, WalkthroughToolTipOptions, WalkthroughToolTipService, LogoutService, LogoutFunction } from '@services/public';
import { CarouselService, CardService, AssessmentService, GoalService } from '@services/member';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MemberModalSelectAvatarComponent } from '@pages/member';
import { NavigationEnd, Router } from '@angular/router';

const PAGE_PATH: string = 'pages.member.walkthrough.page';

export interface walkthroughCard {
  type: cardType;
  src?: string;
  title?: string;
  subTitle?: string;
  message?: string;
  buttonLabel?: string;
  checklistItems?: Array<checklistItem>;
  itemsCompleted?: number;
  daysTillStart?: number;
  buttonType?: string;
  isLast?: boolean;
}
enum buttonType {
  next = 'next',
  continue = 'continue'
}
enum cardType {
  checklist = 'checklist',
  completion = 'completion',
  video = 'video',
  userTurn = 'userTurn'
}
interface checklistItem {
  text: string;
  completed: boolean;
  subItems?: Array<{text:string, complete:boolean}>;
}

@Injectable({
  providedIn: 'root'
})
export class MemberOnboardingService {

  page: {[key: string]: string} = {
    buttonLabelContinue: '',
    buttonLabelNext: '',
    completionSubTitleAssessment: '',
    completionSubTitleOnboarding: '',
    completionSubTitleSkills: '',
    completionTitle: '',
    listAssessment: '',
    listAvatar: '',
    listCompleteTitle: '',
    listCompleteSubTitle: '',
    listCompleteButtonLabel: '',
    listCompleteMessage: '',
    listExternal: '',
    listSkills: '',
    listSubTitleLearn: '',
    listSubTitleSkills: '',
    listTitle: '',
    userTurnTitle: '',
    videoAssessmentTitle: '',
    videoExternalTitle: '',
    videoGrowthTitle: '',
    videoMessage: '',
    videoSkillsTitle: '',
    videoWelcomeSubTitle: '',
    videoWelcomeTitle: '',
  }

  private _isRunning: boolean = false;
  private _guidesInstance: Guides;
  private _guides = [];
  private _navigationSubscription: Subscription;
  private _queue: FunctionQueue;
  private _assessmentUUID: string = '';

  constructor(
    private _assessmentSvc: AssessmentService,
    private _cardSvc: CardService,
    private _carouselSvc: CarouselService,
    private _goalSvc: GoalService,
    private _http: HttpClient,
    private _languageSvc: LanguageService,
    private _modalSvc: BsModalService,
    private _rendererFactory: RendererFactory2,
    private _router: Router,
    private _walkthroughTTSvc: WalkthroughToolTipService,
    private _logoutSvc: LogoutService
  ) {
    // Bind the logout service.
    this._logoutSvc.callAfterLogout(this._afterLogout);

    this._guidesInstance = new Guides(this._router, this._walkthroughTTSvc);
  }

  get isRunning(): boolean {
    return this._isRunning;
  }

  private stepCompleted = new Subject<{complete:boolean, step: number}>();
  stepEvent$ = this.stepCompleted.asObservable();

  emitStepCompleted(value: {complete:boolean, step: number}): void {
    this.stepCompleted.next(value);
  }

  private _onboardingCompleted: Subject<void> = new Subject<void>();
  onboardingEvent$ = this._onboardingCompleted.asObservable();

  emitOnboardingCompleted(): void {
    this._onboardingCompleted.next();
  }

  get walkthroughCompleteEvent(): Observable<void>{
    return this.onboardingEvent$ as Observable<void>;
  }

  destroyOnboarding(): void {
    if (!!this._queue)
      this._queue.cancel();
    this._isRunning = false;
    this._rendererFactory.createRenderer(null, null).removeClass(
      document.body,
      'onboarding'
    );
    this._cardSvc.emitCloseEvent(true);
    this._carouselSvc.destroyCarousel();
    this._modalSvc._hideModal();
    this._modalSvc.removeBackdrop();
    this._router.events.subscribe().unsubscribe();
    if (!!this._navigationSubscription) {
      this._navigationSubscription.unsubscribe();
    }
    this.emitStepCompleted({complete:true, step: 6});
  }

  async showOnboarding(): Promise<void> {
    const promises: Array<Promise<any>> = [];
    promises.push(this._languageSvc.get([PAGE_PATH]).then((value) => {
      if (
        typeof value[PAGE_PATH] !== 'object' ||
        value[PAGE_PATH] === null
      ) return;
      this.page = value[PAGE_PATH];
      for (const key in this.page) {
        promises.push(this._languageSvc.template(this.page[key]).then( value =>
         {
            this.page[key] = value;
            this._guidesInstance.setPage(key, value);
         }
        ));
      }
    }));
    let step = 0;
    promises.push(toVoidPromise(this._getOnboardingData().pipe(map(
      (res: any) => {
        step = this._flagsToStep(res.flags);
        this._assessmentUUID = res.uuid;
        this._guidesInstance.setAssessmentUUID(res.uuid);
      }
    ).bind(this))));
    await Promise.all(promises);
    if (step > 4) return;
    this._guides = this._guidesInstance.getGuides();
    this._isRunning = true;
    this._rendererFactory.createRenderer(null, null).addClass(
      document.body,
      'onboarding'
    );

    this._queue = new FunctionQueue();
    const steps = this._getSteps();
    for (let i: number = step; i < steps.length; i++) {
      for (const task of steps[i]) {
        this._queue.add(this, task, null, false);
      }
    }
  }

  private _getOnboardingData():  Observable<any> {
    return this._http.get<any>(`${config.apiBase}member/onboarding/view`);
  }

  private _updateOnboardingData(flags: number): Observable<any> {
    return this._http.put<any>(
      `${config.apiBase}member/onboarding/update`,
      { flags }
    );
  }

  private _flagsToStep(flag: number): number {
    for (let i: number = 0; i < 6; i++) {
      if (!(flag >> i & 1)) return i;
    }
    return 6;
  }

  private _stepToFlags(step: number): number {
    let i = 0;
    if (step > 0) i += 1;
    if (step > 1) i += 2;
    if (step > 2) i += 4;
    if (step > 3) i += 8;
    if (step > 4) i += 16;
    if (step > 5) i += 32;
    return i;
  }

  private _enforceNavigation(url: string): void {
    if (!!this._navigationSubscription) {
      this._navigationSubscription.unsubscribe();
    }
    this._navigationSubscription = this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!event.urlAfterRedirects.includes(url)) {
          this._router.navigate([url]);
        }
      }
    });
    this._router.navigate([url]);
  }

  private _createCarousel(
    url: string,
    slides: any[],
    enterAnimation?: string,
    exitAnimation?: string
  ): (next: () => Promise<void>) => Promise<void> {
    return async (next: () => Promise<void>) => {
      this._enforceNavigation(url);
      const carousel = this._carouselSvc.createComponent();
      carousel.slides = slides;
      if (!!enterAnimation)
        carousel.enterAnimationClass = enterAnimation;
      if (!!exitAnimation)
        carousel.exitAnimationClass = exitAnimation;
      const cardSubscription = this._cardSvc.closeEvent$.subscribe(value => {
        if (!!value) {
          if(this._carouselSvc.isCarouselVisible)
            carousel.showCarousel();
        }
      });
      carousel.showCarousel();
      const carouselSubscription = this._carouselSvc.onClose.subscribe(data => {
        if (data === 'finished') {
          cardSubscription.unsubscribe();
          carouselSubscription.unsubscribe();
          next();
        }
      });
    }
  }

  private _checklistSetup(step: number): {
    checklistItems: Array<checklistItem>,
    itemsCompleted: number
  } {
    const checklistItems: Array<checklistItem> = [
      { text: this.page.listAvatar, completed: false },
      { text: this.page.listAssessment+' >', completed: false },
      { text: this.page.listSkills+' >', completed: false },
      { text: this.page.listExternal, completed: false },
      { text: this.page.listCelebrate, completed: false }
    ];
    let itemsCompleted = 0;
    if (step > 0){
      for (let i = 0; i < step && i < checklistItems.length; i++) {
        checklistItems[i].completed = true;
        itemsCompleted++;
      }
    }
    return { checklistItems, itemsCompleted };
  }

  private _selectAvatar(
    url: string
  ): (next: () => Promise<void>) => Promise<void> {
    return async (next: () => Promise<void>) => {
      this._enforceNavigation(url);
      const modalRef = this._modalSvc.show(
        MemberModalSelectAvatarComponent, {
          class: 'modal-sm modal-dialog-centered',
          backdrop: 'static',
          keyboard: false
        }
      );
      const subscription = modalRef.onHidden.subscribe(() => {
        subscription.unsubscribe();
        next();
      });
    };
  }

  private _updateStep(
    step: number
  ): (next: () => Promise<void>) => Promise<void> {
    return async (next: () => Promise<void>) => {
      this._updateOnboardingData(this._stepToFlags(step)).subscribe(
        () => next()
      );
    };
  }

  private _runTour(
    url: string,
    tts: WalkthroughToolTipOptions[],
    external?: boolean,
    bodyClass?: string
  ): (next: () => Promise<void>) => Promise<void> {
    return async (next: () => Promise<void>) => {
      this._enforceNavigation(url);
      this._walkthroughTTSvc.setTooltipQueue(tts);
      if (!!bodyClass){
        document.querySelector('body').classList.add(bodyClass);
      }
      if (!!external) {
        const externalValid = this._walkthroughTTSvc.externalFormValid$.subscribe(value => {
          if (value.valid) {
            externalValid.unsubscribe();
            document.querySelector('body').classList.remove(bodyClass)
            next();
          }
        });
      }else {
        const subscription = this._walkthroughTTSvc.ttEvent$.subscribe(value => {
          if (value.complete) {
            subscription.unsubscribe();
            document.querySelector('body').classList.remove(bodyClass)
            next();
          }
        });
      }
    };
  }

  private _doActivity(
    url: string,
    event: Observable<void>
  ): (next: () => Promise<void>) => Promise<void> {
    return async (next: () => Promise<void>) => {
      this._enforceNavigation(url);
      const subscription = event.subscribe(
        () => {
          subscription.unsubscribe();
          next();
        }
      );
    }
  }

  private _destroyWalkthrough: (
    next: () => Promise<void>
  ) => Promise<void> = async (next: () => Promise<void>) => {
    this._walkthroughTTSvc.destroy();
    next();
  }

  private _afterLogout: LogoutFunction = () => {
    if (!!this._navigationSubscription) {
      this._navigationSubscription.unsubscribe();
    }
    this.destroyOnboarding();
  }

/*=========================================================================================*/
  private _getSteps(): Array<Array<(
    next: () => Promise<void>
  ) => Promise<void>>> {
    return [
      [ // Step 0
        this._createCarousel('member/dashboard/activities', [
          {
            type: cardType.video,
            src: 'welcome',
            title: this.page.videoWelcomeTitle,
            subTitle: this.page.videoWelcomeSubTitle,
            buttonLabel: this.page.buttonLabelContinue,
            buttonType: buttonType.continue
          }
        ]),
        this._selectAvatar('member/dashboard/activities'),
        this._updateStep(1)
      ],
      [ // Step 1
        this._createCarousel('member/dashboard/activities', [
          {
            type: cardType.checklist,
            title: this.page.listTitle,
            subTitle: this.page.listSubTitleSkills,
            buttonLabel: this.page.buttonLabelNext,
            buttonType: buttonType.next,
            ...this._checklistSetup(1)
          },
          {
            type: cardType.video,
            src: 'assessment',
            title: this.page.videoAssessmentTitle,
            buttonLabel: this.page.buttonLabelContinue,
            message: this.page.videoMessage,
            buttonType: buttonType.continue
          }
        ]),
        this._runTour(this._guides[0].url, this._guides[0].tts),
        this._runTour(this._guides[1].url, this._guides[1].tts),
        this._createCarousel('member/dashboard/activities', [
          {
            type: cardType.userTurn,
            title: this.page.userTurnTitle,
            buttonLabel: this.page.buttonLabelNext,
            buttonType: buttonType.next
          }
        ]),
        this._doActivity(
          this._router.createUrlTree(
            ['member/assessment/self', this._assessmentUUID]
          ).toString(), this._assessmentSvc.submitSelfEvent
        ),
        this._updateStep(2),
        this._runTour(this._guides[9].url, this._guides[9].tts, false, 'activities')
      ],
      [ // Step 2
        this._createCarousel('member/dashboard/activities', [
          {type: cardType.completion,
            title: this.page.completionTitle,
            subTitle: this.page.completionSubTitleAssessment,
            buttonLabel: this.page.buttonLabelNext,
            buttonType: buttonType.next
          },
          {type: cardType.checklist,
            title: this.page.listTitle,
            subTitle: this.page.listSubTitleSkills,
            buttonLabel: this.page.buttonLabelNext,
            buttonType: buttonType.next,
            ...this._checklistSetup(2)
          },
          {type: cardType.video,
            src: 'skillbuilder',
            title: this.page.videoSkillsTitle,
            buttonLabel: this.page.buttonLabelContinue,
            message: this.page.videoMessage,
            buttonType: buttonType.continue
          }
        ]),
        this._runTour(this._guides[2].url, this._guides[2].tts),
        this._runTour(this._guides[3].url, this._guides[3].tts),
        this._createCarousel('member/dashboard/activities', [
          {
            type: cardType.userTurn,
            title: this.page.userTurnTitle,
            buttonLabel: this.page.buttonLabelNext
          }
        ]),
        this._doActivity(
          'member/skill-builder/goal',
          this._goalSvc.createPlanEvent
        ),
        this._updateStep(3)
      ],
      [ // Step 3
        this._createCarousel('member/dashboard/activities', [
          {
            type: cardType.completion,
            title: this.page.completionTitle,
            subTitle: this.page.completionSubTitleSkills,
            buttonLabel: this.page.buttonLabelNext,
            buttonType: buttonType.next
          },{
            type: cardType.checklist,
            title: this.page.listTitle,
            subTitle: this.page.listSubTitleLearn,
            buttonLabel: this.page.buttonLabelNext,
            buttonType: buttonType.next,
            ...this._checklistSetup(3)
          },{
            type: cardType.video,
            src: 'external',
            title: this.page.videoExternalTitle,
            buttonLabel: this.page.buttonLabelNext,
            message: this.page.videoMessage,
            buttonType: buttonType.continue
          }
        ]),
        this._runTour(this._guides[4].url, this._guides[4].tts),
        this._runTour(this._guides[5].url, this._guides[5].tts),
        this._createCarousel('member/dashboard/activities', [
          {
            type: cardType.userTurn,
            title: this.page.userTurnTitle,
            buttonLabel: this.page.buttonLabelNext
          }
        ]),
        this._runTour(this._guides[6].url, this._guides[6].tts, true),
        this._doActivity('member/assessment/external/update-skills',
        this._assessmentSvc.createExternalEvent),
        this._updateStep(4)
      ],
      [
        this._createCarousel('member/dashboard/activities', [
          {

            type: cardType.checklist,
            title: this.page.listTitle,
            subTitle: this.page.listSubTitleCelebrate,
            buttonLabel: this.page.buttonLabelNext,
            buttonType: buttonType.continue,
            ...this._checklistSetup(4)
          }
        ]),
        async (next: () => Promise<void>) => {
          setTimeout(() => {
            this._createCarousel('member/dashboard/rewards', [
              {
                type: cardType.video,
                src: 'rewards',
                title: this.page.videoGrowthTitle,
                buttonLabel: this.page.buttonLabelNext,
                message: this.page.videoMessage
              }
            ],'animate__backInDown', 'animate__backOutUp')(next);
          }, 200);
        },

        this._runTour(this._guides[7].url, this._guides[7].tts),
        this._createCarousel('member/dashboard/reports', [
          {
            type: cardType.video,
            src: 'reports',
            title: this.page.videoGrowthTitle,
            buttonLabel: this.page.buttonLabelNext,
            message: this.page.videoMessage
          }
        ],'','animate__slideOutRight'),
        this._runTour(this._guides[8].url, this._guides[8].tts),
        this._updateStep(5),
        this._doActivity(
          'member/dashboard/activities',
          this._onboardingCompleted
        ),
        this._destroyWalkthrough,
        window.location.reload.bind(window.location),
      ]
    ];
  }

}
