import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Tab } from '@components';
import { LanguageService, StorageService, TEMP_KEYS, TokenService } from '@services/public';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription } from 'rxjs';
import {
  FirstLoginModalComponent
} from '../first-login-modal/first-login-modal.component';
import { APIErrors, FunctionQueue, isAPIErrors } from '@util';
import { NotificationsService } from '@services/member/notifications.service';
import {
  FeatureModalComponent
} from '../feature-modal/feature-modal.component';
import {
  GeneralModalComponent
} from '../general-modal/general-modal.component';
import { MemberOnboardingService, FinalCardService, CertificatesService } from '@services/member';
import { CertificateModalComponent } from '../certificate-modal/certificate-modal.component';


const PAGE_PATH: string = 'pages.member.dashboard.page';

@Component({
  selector: 'app-member-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class MemberDashboardComponent
  implements OnInit, AfterViewInit, OnDestroy
{

  TABS: Array<Tab> = [
    {
      key: 'activities',
      id: 'member-dashboard-page-activities-tab',
      display: '',
      link: '/member/dashboard/activities'
    },
    {
      key: 'reports',
      id: 'member-dashboard-page-reports-tab',
      display: '',
      link: '/member/dashboard/reports'
    },
    {
      key: 'rewards',
      id: 'member-dashboard-page-rewards-tab',
      display: '',
      link: '/member/dashboard/rewards'
    }
  ];

  selectTab: ReplaySubject<string> = new ReplaySubject<string>(1);

  private _subscriptions: Subscription = new Subscription();
  private _modalRef: BsModalRef;
  private _popupQueue: FunctionQueue = new FunctionQueue();

  // Page langauge.
  page: {[key: string]: string} = {
    activities: '',
    reports: '',
    rewards: ''
  }

  constructor(
    private _languageSvc: LanguageService,
    private _modalSvc: BsModalService,
    private _notificationSvc: NotificationsService,
    private _onboardingSvc: MemberOnboardingService,
    private _router: Router,
    private _storageSvc: StorageService,
    private _tokenSvc: TokenService,
    private _finalCardSvc: FinalCardService,
    private _certificateSvc: CertificatesService
  ) { }

  async ngOnInit(): Promise<void> {
    this._subscriptions.add(this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.selectTab.next(event.urlAfterRedirects);
      }
    }));

    this.selectTab.next(this._router.url);

    // Get page language.
    this._languageSvc.get([PAGE_PATH]).then(
      value => {
        if (
          typeof value[PAGE_PATH] !== 'object' ||
          value[PAGE_PATH] === null
        ) return;
        this.page = value[PAGE_PATH];
        for (const key in this.page) {
          this._languageSvc.template(this.page[key]).then(
            value => {
              this.page[key] = value;
              const tab = this.TABS.find(tab => tab.key === key);
              if (!!tab) tab.display = value;
            }
          );
        }
      }
    );

    if (!this._onboardingSvc.isRunning && !!this._tokenSvc.accessToken) {
      await this._onboardingSvc.showOnboarding();
    }

    if (!this._onboardingSvc.isRunning && !!this._finalCardSvc.getFinalStatus()
        ) {
      // Handle first login popup.
      if (!!this._storageSvc.getTempStorage(TEMP_KEYS.IS_FIRST_LOGIN)) {
        this._storageSvc.setTempStorage(TEMP_KEYS.IS_FIRST_LOGIN, false);
        this._popupQueue.add(this, next => {
          this._modalRef = this._modalSvc.show(FirstLoginModalComponent, {
            class: 'modal-md modal-dialog-centered',
            backdrop: 'static',
            keyboard: true
          });
          const subscription = this._modalRef.onHidden.subscribe(() => {
            subscription.unsubscribe();
            next();
          });
        }, null, false);
      }
  
      // Show new notifications.
      this._notificationSvc.loadNotifications().subscribe(
        (res: any | APIErrors) => {
          if (!isAPIErrors(res)) {
            if (res.length > 0) this._popupQueue.add(this, next => {
              this._modalRef = this._modalSvc.show(FeatureModalComponent, {
                initialState: { userNotifications: res },
                class: 'centered-all'
              });
              const subscription = this._modalRef.onHidden.subscribe(() => {
                subscription.unsubscribe();
                next();
              });
            }, null, false);
          }
        }
      );

      // Show new certification notifications
      this._certificateSvc.loadCertificateNotifications().subscribe(
        (res: any | APIErrors) => {
          if (!isAPIErrors(res)) {     
            if (res.length >  0) {
              this._popupQueue.add(this, next => {  
                this._modalRef = this._modalSvc.show(CertificateModalComponent, {
                  initialState: { certificateData: res },
                  class: 'centered-all',
                });
                const subscription = this._modalRef.onHidden.subscribe(
                  () => {
                    subscription.unsubscribe();
                    next();
                  }
                );
              }, null, false);
            }
          }
        }
      )
    }

  }

  ngAfterViewInit(): void {
    if (!this._onboardingSvc.isRunning) {
      // Handle video popups.
      if (!!this._storageSvc.getTempStorage(TEMP_KEYS.IS_FIRST_SELF_ASSESSMENT)) {
        this.playChallengeVideo(
          'reports-page', '', TEMP_KEYS.IS_FIRST_SELF_ASSESSMENT
        );
      }
      if(!!this._storageSvc.getTempStorage(TEMP_KEYS.IS_REWARDS_ORIENTATION)) {
        this.playChallengeVideo('rewards', '', TEMP_KEYS.IS_REWARDS_ORIENTATION);
      }
    }
  }

  playChallengeVideo(src: string, script: string, key: TEMP_KEYS): void {
    this._popupQueue.add(this, next => {
      this._modalRef = this._modalSvc.show(GeneralModalComponent, {
        initialState: {
          modalInfo: {
            videoSrc: src,
            transcript: script
          },
        },
        class: 'centered-all'
      });
      const subscription = this._modalRef.onHidden.subscribe(() => {
        subscription.unsubscribe();
        this._storageSvc.setTempStorage(key, null);
        next();
      });
    }, null, false);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

}
